import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../helpers/context';

import Template from '../templates/projects';

const ProjektyPage = ({ data, location }) => {
  const cats = data.med.projectCategories;
  const projects = data.med.pageProjects;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          contextData={data}
          contextSet={set}
          langTo={'/en/works'}
          data={projects}
          cats={cats}
          location={location}
        />
      )}
    </ContextConsumer>
  );
};

export const pageProjektyQuery = graphql`
  {
    med {
      pageProjects(where: { id: "ckjttb1xs2rfp0b53cobpiy6j" }, locales: pl_PL) {
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        projects {
          intro {
            html
            markdown
            raw
          }
          slug
          title
          projectCategories {
            slug
            title
          }
        }
      }
      projectCategories(locales: pl_PL) {
        title
        slug
      }
    }
  }
`;

export default ProjektyPage;
